<template>
  <b-modal 
    id="history-appointment-docs-modal" 
    hide-header 
    hide-footer 
    centered 
    @hidden="closeModal"
    v-if="attendanceId || attendance">
    <div class="header">
      <p>Histórico de documentos</p>
      <v-close @click="closeModal" />
    </div>

    <div class="body">
      <span v-for="(doc, index) in docs" :key="index">
        <div class="file-container">
          <v-pdf />
          <p>{{ typeDoc[doc.type_id] }}</p>
        </div>

        <a @click="openFile(doc)">Ver</a>
      </span>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'
import Pdf from '@/assets/icons/pdf.svg'
import { getDocTypes } from '@/utils/documentHelper.js'

export default {
  components: {
    'v-close': Close,
    'v-pdf': Pdf
  },
  props: {
    attendance: Object,
    attendanceId: String,
  },
  data() {
    return {
      docs: [],
      loadingDocs: false,
      typeDoc: getDocTypes(),
      newAttendanceId: null
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('history-appointment-docs-modal')
      this.$emit('closeModal')
    },
    async openFile(attendanceFile) {
      const res = await this.api.getDocTemporaryUrl(attendanceFile.path)
      window.open(res.data, '_blank')
    },
    getAttendanceFiles() {
      this.loadingDocs = true
      this.api.getAttendanceFiles(this.newAttendanceId)
      .then(res => {
        this.docs = res.data
      })
      .catch(err => { console.log('ERR getAttendanceFiles => ', err)})
      .finally(() => { this.loadingDocs = false })
    },
  },
  watch: {
    attendance(val) {
      this.newAttendanceId = val
      this.getAttendanceFiles()
    },
    attendanceId(val) {
      this.newAttendanceId = val
      this.getAttendanceFiles()
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: 'Nunito Sans';
  font-style: normal;
}

#history-appointment-docs-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 700px;
    border-radius: 8px;
    border: none;

    .modal-body {
      padding: 0;

      .header {
        padding: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }

      .body {
        border-top: 1px solid var(--neutral-200);
        padding: 12px 0;
        overflow-x: hidden;
        overflow-y: scroll;
        white-space: nowrap;
        max-height: 300px;

        span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 0 24px;
          padding: 12px 0;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            svg {
              height: 24px;
              width: 24px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }

        span + span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid var(--neutral-200);
          margin: 0 24px;
          padding: 12px 0;

          .file-container {
            display: flex;
            flex-direction: row;
            gap: 20px;

            svg {
              height: 24px;
              width: 24px;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: var(--type-active);
            }
          }

          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: var(--blue-500);
            cursor: pointer;
          }
        }
      }

      .body::-webkit-scrollbar {
        width: 12px;
      }

      .body::-webkit-scrollbar-track {
        background: var(--neutral-000);
        border-radius: 0 0 8px 0;
      }

      .body::-webkit-scrollbar-thumb {
        background-color: var(--neutral-300);
        border-radius: 100px;
        border: 3px solid var(--neutral-000);
      }
    }
  }
}
</style>
